export default class extends Controller {
  static targets = ["updatetime"];

  initialize() {
    this.boundBeforeUnload = this.beforeUnload.bind(this);
   window.addEventListener('beforeunload', this.boundBeforeUnload);
   document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }

  connect() {
    this.remainingTime = this.updatetimeTarget.dataset.timerDuration;
    if (this.updatetimeTarget.dataset.timerStart == 'true'){
      this.updateTime();
    }
  }

  beforeUnload(event) {
    const room_status_id = this.updatetimeTarget.dataset.roomStatusId;
    const time_duration = this.updatetimeTarget.dataset.timerDuration;
    const url = `/rooms/${room_status_id}/update_time`;
    const data = {
      time_duration,
    };
    fetch(url, {
      method: "PUT",
      headers: {
           'Content-Type': 'application/json'
       },
       body: JSON.stringify(data)
    })
  }

  disconnect(){
    const room_status_id = this.updatetimeTarget.dataset.roomStatusId;
    const time_duration = this.updatetimeTarget.dataset.timerDuration;
    const url = `/rooms/${room_status_id}/update_time`;
    const data = {
        time_duration,
    };
    fetch(url, {
      method: "PUT",
      headers: {
           'Content-Type': 'application/json'
       },
       body: JSON.stringify(data)
    })
  }

  updateTime() {
    if (!this.timerInterval) {
      this.timerInterval = setInterval(this.decrementTime.bind(this), 1000);
    }
  }

  decrementTime() {
    if (this.remainingTime > 0) {
      this.remainingTime--;

      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;

      this.updatetimeTarget.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;

      if (this.remainingTime === 0) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
        this.updatetimeTarget.classList.add("text-danger");
        this.updatetimeTarget.classList.remove("text-success");
      } else {
        this.updatetimeTarget.classList.add("text-success");
        this.updatetimeTarget.classList.remove("text-danger");
      }

      this.updatetimeTarget.dataset.timerDuration = this.remainingTime;
      this.updatetimeTarget.dataset.timerStart = 'true';
    }
  }

  pauseTime() {
      const housekeeping_id = this.updatetimeTarget.dataset.housekeepingScheduleId;
      const room_status_id = this.updatetimeTarget.dataset.roomStatusId;
      const time_duration = this.updatetimeTarget.dataset.timerDuration;
      const url = `/rooms/${room_status_id}/update_time`;
      const data = {
          time_duration,
          housekeeping_id,
      };
      fetch(url, {
        method: "PUT",
        headers: {
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(data)
      })
      clearInterval(this.timerInterval);
      this.timerInterval = null;
      this.updatetimeTarget.dataset.timerStart = 'false';
  }

  update_room_time(){
    const room_status_id = this.updatetimeTarget.dataset.roomStatusId;
    const time_duration = this.updatetimeTarget.dataset.timerDuration;
    const url = `/rooms/${room_status_id}/update_time`;
    const data = {
        time_duration,
    };
    fetch(url, {
      method: "PUT",
      headers: {
           'Content-Type': 'application/json'
       },
       body: JSON.stringify(data)
    })
  }

  handleVisibilityChange() {
    if (document.hidden) {
      if (this.updatetimeTarget.dataset.timerStart == 'true'){
      this.update_room_time();
      }
    }
    else {
      if (this.updatetimeTarget.dataset.timerStart == 'true'){
        const room_status_id = this.updatetimeTarget.dataset.roomStatusId;
        const url = `/rooms/${room_status_id}/get_time`;
        fetch(url, {
          method: "GET",
          headers: {
           'Content-Type': 'application/json'
          }
        })
        .then(response =>response.json())
        .then(data => {
          console.log('Room time:', data.room_time);
          this.remainingTime = data.room_time;
        })
      }
    }
  }
}
